import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/shop/user',
    method: 'post',
    data
  })
}
export function get(params) {
  return request({
    url: 'api/shop/user',
    method: 'get',
    params
  })
}


export function del(id) {
  return request({
    url: 'api/shop/user/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/shop/user',
    method: 'put',
    data
  })
}
export function downloadUser() {
  return request({
    url: 'api/users/download',
    method: 'get',
    responseType: 'blob'
  })
}


export function getUserByErpCode(code) {
  return request({
    url: 'api/shop/user/fromErp',
    method: "get",
    params: {
      code
    }
  })
}