<template>
  <el-dialog :visible.sync="dialog" :close-on-click-modal="false" :title="this.isAdd ? '新增销售人员' : '编辑销售人员'" append-to-body @closed="form = null" width="720px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="84px" v-if="form">
      <div class="h sb" v-if="entType === 'serta'">
        <el-form-item label="ERP业务员" prop="erpCode" style="width: 48%;" label-width="95px">
          <!-- <el-input v-model="form.erpCode" :maxlength="50" :disabled="searching" @change="handleErpCodeInfo">
          <el-button slot="append" icon="el-icon-search" :loading="searching" @click="handleErpCodeInfo"></el-button>
          </el-input>-->
          <quick-select v-model="form.erpCode" filterable placeholder="请选择" url="api/shop/user/fromErp" display-field="Name" value-field="EmployeeNum" @change="empChange" style="width: 100%;" />
        </el-form-item>
        <el-form-item label label-width="5px" style="width: 42%;">
          <el-checkbox v-model="checked" @change="empChange">用户名/姓名与ERP相同</el-checkbox>
        </el-form-item>
      </div>
      <div class="h sb">
        <el-form-item label="用户名" prop="username" style="width: 48%;">
          <el-input v-model="form.username" />
        </el-form-item>
        <el-form-item label="密码" prop="password" style="width: 48%;" v-if="isAdd">
          <el-input type="password" v-model="form.password" :maxlength="20" />
        </el-form-item>
        <el-form-item label="密码" style="width: 48%;" v-else>
          <el-input type="password" v-model="form.password" :maxlength="20" placeholder="不修改密码请置空" />
        </el-form-item>
      </div>

      <div class="h sb">
        <el-form-item label="姓名" prop="userRealName" style="width: 48%;">
          <el-input v-model="form.userRealName" :maxlength="20" />
        </el-form-item>
        <el-form-item label="电话" prop="phone" style="width: 48%;">
          <el-input v-model.number="form.phone" :maxlength="11" />
        </el-form-item>
      </div>
      <div class="h sb">
        <el-form-item label="角色" prop="_roleIds" style="width: 48%;">
          <quick-select v-model="form._roleIds" filterable url="api/roles" multiple :params="{ enterpriseId: targetEnterprise }" style="width: 100%;" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email" style="width: 48%;">
          <el-input v-model="form.email" :maxlength="50" />
        </el-form-item>
      </div>
      <el-form-item label="门店" prop="shopIds">
        <quick-select v-model="form.shopIds" filterable multiple placeholder="选择门店" url="api/shop" display-field="shopName" value-field="id" style="width: 100%;" />
      </el-form-item>
      <el-form-item label="状态" prop="enabled" style="width: 48%;">
        <el-radio-group v-model="form.enabled">
          <el-radio :label="true">激活</el-radio>
          <el-radio :label="false">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit, getUserByErpCode } from "@/api/shopUser";
import { getLevel } from "@/api/role";
import { getStatementType } from "@/api/capitalPool";
import initDict from "@/mixins/initDict";

export default {
  mixins: [initDict],
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.getDict("user_status");
    getStatementType().then((res) => {
      this.entType = res;
    });
    if (!this.$erp.enable()) {
      this.rules.username.push({
        pattern: /^(?!JMJ|JMS|ZYZ|ZYS|sales|SD|D).*/i,
        message: "不能以SD/D/JMJ/JMS/ZYZ/ZYS/sales开头",
        trigger: "blur",
      });
    }
  },
  data() {
    return {
      checked: false,
      dialog: false,
      loading: false,
      searching: false,
      entType: null,
      form: {
        erpCode: null,
        username: null,
        userRealName: null,
        email: null,
        enabled: "false",
        roles: [],
        _roleIds: [],
        jobId: null,
        shopId: null,
        phone: null,
      },
      level: 3,
      targetEnterprise: null,
      rules: {
        erpCode: [
          { required: true, message: "请输入ERP编码", trigger: "change" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],

        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        userRealName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, trigger: "blur", message: "请输入电话号码" },
          {
            pattern: /^\d{11}$/gi,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        enabled: [{ required: true, message: "状态不能为空", trigger: "blur" }],
        shopIds: [{ required: true, message: "请选择门店", trigger: "blur" }],
        _roleIds: [
          { required: true, message: "请选择角色" },
          { type: "array", min: 1, message: "请选择角色" },
        ],
      },
    };
  },

  methods: {
    empChange(k, v, obj) {
      if (obj) this.form.name = obj.Name;
      if (this.checked) {
        this.form.username = this.form.erpCode;
        this.form.userRealName = this.form.name;
      }
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let fd = JSON.parse(JSON.stringify(this.form));
          fd.roles = fd._roleIds.map((rid) => {
            return { id: rid };
          });
          fd.shopId = "," + fd.shopIds.join(",") + ",";
          delete fd.shopIds;
          delete fd._roleIds;
          if (this.isAdd) {
            this.doAdd(fd);
          } else this.doEdit(fd);
        } else {
          return false;
        }
      });
    },
    handleErpCodeInfo() {
      if (this.form.erpCode) {
        this.searching = true;
        getUserByErpCode(this.form.erpCode)
          .then((res) => {
            if (res && res.length) {
              this.form.userRealName = res[0].Name;
              this.form.username = res[0].EmployeeNum;
            } else {
              throw new Error();
            }
          })
          .catch((err) => {
            this.$notify({
              title: "未找到ERP中对应的销售人员",
              type: "success",
              duration: 2500,
            });
            this.form.erpCode = "";
          })
          .finally((_) => {
            this.searching = false;
          });
      }
    },
    doAdd(fd) {
      add(fd)
        .then((res) => {
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
          this.dialog = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit(fd) {
      edit(fd)
        .then((res) => {
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
          this.dialog = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    resetForm(shopIds, form) {
      this.form =
        form ||
        Object.assign({
          username: "",
          password: "",
          userRealName: "",
          email: "",
          enabled: true,
          roles: [],
          _roleIds: [],
          jobId: null,
          shopIds: shopIds,
          shopId: "",
          phone: "",
        });
      this.dialog = true;
    },
  },
};
</script>